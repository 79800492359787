import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/assess',
        name: 'assess',
        component: () => import('../views/assess/interest.vue'),
    },

    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login'),
    },
    {
        path: '/report',
        name: 'report',
        component: () => import('../views/tianbao/report/download.vue'),
    },
    {
        path: '/',
        name: '',
        component: () => import('../views/index'),
        children: [
            {
                path: '',
                component: () => import('../views/user/index'),
                children: [
                    {
                        path: '',
                        name: 'user',
                        component: () => import('../views/user/user_list'),
                    },
                    {
                        path: 'user/verify-1',
                        name: 'verify',
                        component: () => import('../views/user/verify-1'),
                    },
                    {
                        path: 'user/verify-2',
                        name: 'verify-2',
                        component: () => import('../views/user/verify-2'),
                    },
                ]
            },
        ]
    },

    {
        path: '/tianbao',
        component: () => import("../views/tianbao/index"),
        children: [
            {
                path: '',
                name: 'benke',
                component: () => import('../views/tianbao/benke'),
            },
            {
                path: 'zhuanke',
                name: 'zhuanke',
                component: () => import('../views/tianbao/zhuanke'),
            },
        ]
    },
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    // mode: "history",
    base: process.env.BASE_URL,
    routes
});


router.beforeEach((to, from, next) => {
    console.log(to.name)
    // to: Route: 即将要进入的目标 路由对象
    // from: Route: 当前导航正要离开的路由
    // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。
    const ExpertInfo = localStorage.getItem('ExpertInfo') // 是否登录
    // 未登录状态；当路由到nextRoute指定页时，跳转至login


    if (to.name === 'login' || to.name === 'assess') {
        next()
        return
    }

    if (!ExpertInfo) {
        router.push('/login')
    } else {
        next()
    }
    // // // 已登录状态；当路由到login时，跳转至home

    // next();
})

export default router
